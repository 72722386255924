<template>
  <div class="login-page d-flex flex-column">
    <img :src="logo" class="mb-4" height="250" />    
    <Card class="d-flex py-3">
      <div class="d-flex" slot="card-body">
        <div class="input-group form-group login-input">
          <div>
            <b-input
              v-model="loginForm.username"
              placeholder="usuário"
              class="mb-3"
            />
            <b-input
              type="password"
              v-model="loginForm.password"
              placeholder="Senha"
            />
          </div>
        </div>
        <b-button
          variant="primary-custom"
          @click="login()"
          class="login-button ml-3 d-flex align-items-center"
          >ACESSAR</b-button
        >
      </div>
    </Card>
    <b-alert class="mt-3" variant="danger" :show="showError"
      >Usuário ou senha invalido</b-alert
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      showError: false,
      loginForm: {
        username: "",
        password: "",
      },
      application_name: "",
    };
  },
  computed:{
    logo(){
      return process.env.VUE_APP_LOGO
    }
  },
  methods: {
    async login(e) {
      try {
        let isLogged = await this.$store.dispatch("auth/login", this.loginForm);
        console.log("isLogged", isLogged);

        if (isLogged) {
          const indexPage = process.env.VUE_APP_INDEX_PAGE;
          this.$router.push(indexPage);
        } else {
          this.showError = true;
          setTimeout(() => {
            this.showError = false;
          }, 3000);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.application_name = process.env.VUE_APP_APPLICATION_NAME;
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
</style>
<style lang="scss" scoped>
.logo {
  font-family: "Staatliches", serif;
  font-size: 48px;
  color: rgb(20, 19, 19);
  text-shadow: 0.1mm 0.1mm #cdcdcd;
}
.login-page {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  @include gradient-y(theme-color("login-gradient-1"), theme-color("login-gradient-2"));
}
.login-input {
  margin: unset;
}
.login-button {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 12px;
  font-size: 75%;
  font-weight: 400;
}
.login-button:hover {
  color: black;
  cursor: pointer;
  user-select: none;
}
#inTurnFadingTextG {
  width: 150px;
  margin: auto;
}

.inTurnFadingTextG {
  color: rgb(0, 0, 0);
  font-family: Arial;
  font-size: 16px;
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  float: left;
  animation-name: bounce_inTurnFadingTextG;
  -o-animation-name: bounce_inTurnFadingTextG;
  -ms-animation-name: bounce_inTurnFadingTextG;
  -webkit-animation-name: bounce_inTurnFadingTextG;
  -moz-animation-name: bounce_inTurnFadingTextG;
  animation-duration: 2.09s;
  -o-animation-duration: 2.09s;
  -ms-animation-duration: 2.09s;
  -webkit-animation-duration: 2.09s;
  -moz-animation-duration: 2.09s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}

#inTurnFadingTextG_1 {
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
}
#inTurnFadingTextG_2 {
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
}
#inTurnFadingTextG_3 {
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}
#inTurnFadingTextG_4 {
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}
#inTurnFadingTextG_5 {
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}
#inTurnFadingTextG_6 {
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}
#inTurnFadingTextG_7 {
  animation-delay: 1.64s;
  -o-animation-delay: 1.64s;
  -ms-animation-delay: 1.64s;
  -webkit-animation-delay: 1.64s;
  -moz-animation-delay: 1.64s;
}
#inTurnFadingTextG_8 {
  animation-delay: 1.79s;
  -o-animation-delay: 1.79s;
  -ms-animation-delay: 1.79s;
  -webkit-animation-delay: 1.79s;
  -moz-animation-delay: 1.79s;
}
#inTurnFadingTextG_9 {
  animation-delay: 1.94s;
  -o-animation-delay: 1.94s;
  -ms-animation-delay: 1.94s;
  -webkit-animation-delay: 1.94s;
  -moz-animation-delay: 1.94s;
}
#inTurnFadingTextG_10 {
  animation-delay: 2.09s;
  -o-animation-delay: 2.09s;
  -ms-animation-delay: 2.09s;
  -webkit-animation-delay: 2.09s;
  -moz-animation-delay: 2.09s;
}

@keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}

@-o-keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}

@-ms-keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}

@-webkit-keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}

@-moz-keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}
</style>
